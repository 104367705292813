<template>
  <section id="header">
    <h1>ABOUT US</h1>
  </section>
  <section id="worldmoves" class="container">
    <h1>We reimagine the way the world moves for the better</h1>
    <p>
      Movement is what we power. It’s our lifeblood. It runs through our veins.
      It’s what gets us out of bed each morning. It pushes us to constantly
      reimagine how we can move better. For you. For all the places you want to
      go. For all the things you want to get. For all the ways you want to earn.
      Across the entire world. In real time. At the incredible speed of now.
    </p>
  </section>
  <!-- <section id="headertwo">
    <div class="content">
      <h1>A letter from our CEO</h1>
      <p>
        Read about our team’s commitment to provide everyone on our global
        platform with the technology that can help them move ahead.
      </p>
    </div>
  </section> -->
  <section id="services" class="container">
    <div class="row service">
      <div class="col-md-6">
        <img
          src="https://www.uber-assets.com/image/upload/f_auto,q_auto:eco,c_fill,w_698,h_465/v1603308507/assets/f6/0c9607-ae68-43d5-9e88-79f3011d88ee/original/Sustainability.jpg"
        />
      </div>
      <div class="col-md-6 content">
        <h1>Sustainability</h1>
        <p class="subtitle">
        nRide is the world's largest peer-to-peer car rental where you can book any car you want, wherever you want it, from local hosts across the USA.A vibrant community of local hosts, with nRide guests can choose from over 850 unique makes and models,while hosts earn extra money to offset the costs of car ownership.    
        </p>
        <p class="subtitle">
        Whether it’s a truck to help on moving day, a swanky exotic for a luxurious weekend away, or a classic cruiser for a picture-perfect road trip, with more than 400,000 vehicles listed worldwide, nRide lets you find the perfect vehicle for your next adventure.
        </p>
      </div>
    </div>
  </section>

  <section id="owner" class="container">
    <div class="row service">
      <div class="col-md-6">
        <img
          src="https://www.uber-assets.com/image/upload/f_auto,q_auto:eco,c_fill,w_698,h_465/v1554854756/assets/74/0853d5-80e4-414a-91d7-6fd0b15a136d/original/UberIM_20250-medium-%282%29.jpg"
        />
      </div>
      <div class="col-md-6 content">
        <h1>Rides and beyond</h1>
        <p class="subtitle">
          In addition to helping riders find a way to go from point A to point
          B, we're helping people order food quickly and affordably, removing
          barriers to healthcare, creating new freight-booking solutions, and
          helping companies provide a seamless employee travel experience. And
          always helping drivers and couriers earn.
        </p>
      </div>
    </div>
  </section>

  <section id="CompanyInfo" class="container">
    <div class="row company">
      <h1 class="mb-4">Company info</h1>
      <div class="col-md-4">
        <div class="border">
        
        <img src="@/assets/share_list_your_car_graphic.png"
            width="100%"
            height="100%"
          />
        </div>
        <div class="m-2">
          <h4>Who's driving nRide</h4>
          <p class="subtitle">
            We’re building a culture within nRide that emphasizes doing the right
            thing, period, for riders, drivers, and employees. Find out more
            about the team that’s leading the way.
          </p>
        </div>
      </div>
      <div class="col-md-4 content">
        <div>
          <img
            src="https://www.uber-assets.com/image/upload/f_auto,q_auto:eco,c_fill,w_450,h_300/v1619719896/assets/96/8d24b6-76cd-4dcb-8b89-e3125c603538/original/test2259.jpg"
            width="100%"
            height="100%"
          />
        </div>
        <div class="m-2">
          <h4>Getting diversity right</h4>
          <p class="subtitle">
            It’s our goal to create a workplace that is inclusive and reflects
            the diversity of the cities we serve—where everyone can be their
            authentic self, and where that authenticity is celebrated as a
            strength. By creating an environment where people from every
            background can thrive, we’ll make nRide a better company—for our
            employees and our customers.
          </p>
        </div>
      </div>
      <div class="col-md-4 content">
        <div>
          <img
            src="https://www.uber-assets.com/image/upload/f_auto,q_auto:eco,c_fill,w_450,h_300/v1664822806/assets/b6/c8a24d-8e79-45a1-9e03-adead244ef0a/original/about-ec-image-01-thumb.png"
            width="100%"
            height="100%"
          />
        </div>
        <div class="m-2">
          <h4>Acting with integrity</h4>
          <p class="subtitle">
            nRide Ethics & Compliance Program Charter outlines our commitment
            to integrity at the highest levels within the company. Transparency
            is critical to an ethical culture; we achieve this through our
            Integrity Helpline and suite of scalable and effective compliance
            initiatives.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "about",
  components: {},
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
#header {
  height: 100vh;
  display: flex;
  align-items: end;
  background: url("https://www.uber-assets.com/image/upload/v1555699210/assets/63/cdb12d-a0cd-49fa-a6c0-6184a84d99ea/original/About_us_600.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (min-width: 1500px) {
    height: 95vh;
    display: flex;
    align-items: center;
    background: url("https://www.uber-assets.com/image/upload/v1555699210/assets/63/cdb12d-a0cd-49fa-a6c0-6184a84d99ea/original/About_us_600.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  h1 {
    font-size: 3rem;
    color: white;
    margin-left: 11rem;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 1.8rem;
      margin-left: 1rem;
      margin-bottom: 1.8rem;
    }
  }
}

#headertwo {
  height: 100vh;
  display: flex;
  background: url("https://www.uber-assets.com/image/upload/v1555699210/assets/63/cdb12d-a0cd-49fa-a6c0-6184a84d99ea/original/About_us_600.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  @media (min-width: 1500px) {
    height: 95vh;
    display: flex;
    align-items: center;
    background: url("https://www.uber-assets.com/image/upload/v1555699210/assets/63/cdb12d-a0cd-49fa-a6c0-6184a84d99ea/original/About_us_600.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .content {
    padding: 3rem 3rem;
    margin-left: 4rem;
    width: 40%;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0rem;
      text-align: center;
      padding: 1rem 1rem;
    }

    h1 {
      font-size: 3rem;
      color: white;

      @media (max-width: 768px) {
        font-size: 2.5rem;
        color: white;
      }
    }

    p {
      margin-top: 1rem;
      font-size: 1.2rem;
      color: white;
    }
  }
}

#worldmoves {
  padding: 2rem;
  @media (min-width: 1500px) {
    padding-top: 4rem;
    margin-left: 9rem;
  }
  .content {
    h1 {
      font-size: 3rem;
     
    }
  
  }
  h1{
     width: 80% !important;
  }
  p{
    width: 64% !important;
  }
}
#CompanyInfo{
  border{
  border: 1px solid black;}
}
#services {
  padding-top: 4rem;

  @media (min-width: 1500px) {
    padding-top: 8rem;
  }

  .service {
    margin-bottom: 4rem;
    img {
      width: 100%;
      height: auto;
      @media (min-width: 1500px) {
        width: 100%;
        height: auto;
        padding: 2rem;
      }
    }

    .content {
      h1 {
        font-size: 1.6rem;
        font-weight: 700;
        margin-left: 1rem;
        margin-top: 1.6rem;
        @media (min-width: 1500px) {
          font-size: 2rem;
          font-weight: 700;
          margin-left: 4rem;
          margin-top: 1.6rem;
        }
      }

      p.subtitle {
       font-size: 1rem;
       margin-top: 0.7rem;
       margin-left: 1rem;
        @media (max-width: 768px) {
          width: 95%;
        }
        @media (min-width: 1500px) {
          font-size: 1.2rem;
          width: 70%;
          margin-top: 2rem;
          margin-left: 4rem;
        }
      }
    }
  }
}

#owner {
  .service {
    margin-bottom: 4rem;
    align-items: center;
    img {
      width: 100%;
      height: auto;
      @media (min-width: 1500px) {
        width: 100%;
        height: auto;
        padding: 2rem;
      }
    }

    .content {
      h1 {
        font-size: 1.6rem;
        font-weight: 700;
        margin-left: 1rem;
        margin-top: 1.6rem;
        @media (min-width: 1500px) {
          font-size: 2rem;
          font-weight: 700;
          margin-left: 4rem;
          margin-top: 1.6rem;
        }
      }

      p.subtitle {
       font-size: 1rem;
       margin-top: 0.7rem;
       margin-left: 1rem;
        @media (max-width: 768px) {
          width: 93%;
        }
        @media (min-width: 1500px) {
          font-size: 1.2rem;
          width: 70%;
          margin-top: 2rem;
          margin-left: 4rem;
        }
      }
    }
  }
}
.Company {
  h1 {
       font-size: 0.5rem !important;
  h4{
     margin-top: 1.5rem !important;
    font-size: 1.2rem !important;
    }
  }
}
</style>