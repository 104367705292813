<template>
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
            <router-link class="navbar-brand" :to="{name:'Home'}">nRIDE</router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                           data-bs-toggle="dropdown" aria-expanded="false">
                            Company
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="/aboutus">About Us</a></li>
                            <!-- <li><a class="dropdown-item" href="#">How we work</a></li> -->
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li><a class="dropdown-item" href="/policies">Policies</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">COVID-19 Safety</a>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="serviceDropdown" role="button"
                           data-bs-toggle="dropdown" aria-expanded="false">
                            <font-awesome-icon :icon="['fa','th']"/>
                            Services
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="serviceDropdown">
                            <li><router-link :to="{name:'RentalService'}" class="dropdown-item">Rental</router-link></li>
                            <li><router-link :to="{name:'ShareService'}"  class="dropdown-item">Share</router-link></li>
                            <li><router-link :to="{name:'HostService'}"  class="dropdown-item">Host</router-link></li>
                            <li><router-link :to="{name:'CorporateService'}"  class="dropdown-item">Corporate</router-link></li>
                        </ul>
                    </li>
                   <!-- <li class="nav-item">
                        <a class="nav-link" href="/services/host">Start Earning</a>
                    </li> -->
                    <li class="nav-item">
                        <a class="nav-link" href="/faqs">FAQs</a>
                    </li>
                </ul>

            </div>
        </div>
    </nav>


</template>

<script>
    export default {
        name: "toolbar"
    }
</script>

<style scoped lang="scss">
    .navbar {
        background: $theme-color-deep;
        /*padding-left: 4rem;*/
        /*padding-right: 4rem;*/
        font-family: $heading-font;


        @media (min-width: 1400px) {
            /*padding-left: 10rem;*/
            /*padding-right: 10rem;*/
        }

        .navbar-brand {
            color: white;
            font-weight: 600;
            letter-spacing: 0.1rem;
            margin-right: 2rem;
        }

        .navbar-nav {
            .nav-item {
                padding-left: 0.2rem;
                padding-right: 0.2rem;

                .nav-link {
                    color: white;
                    padding-left: 1.2rem;
                    padding-right: 1.2rem;
                    font-size: 0.85rem;
                    letter-spacing: 0.05rem;
                    border-radius: 24px;
                    font-weight: 400;

                    &:hover, &.show {
                        background: $theme-color-med;

                    }
                }

                .dropdown-menu {
                    margin-top: 0.59rem;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    box-shadow: $theme-shadow;
                    padding-top: 0;
                    padding-bottom: 0;


                    .dropdown-item {
                        color: gray;
                        font-size: 0.85rem;
                        font-weight: 400;
                        padding-top: 0.6rem;
                        padding-bottom: 0.6rem;

                        &:hover, &:active {
                            background-color: $theme-color-light;
                            color: black;
                        }
                    }
                }
            }
        }


    }
</style>